<template>
  <Modal
    wrap-class-name="c-bitts-modal"
    :open="visible"
    :width="width"
    :z-index="1035"
    :keyboard="useKeyboardToClose"
    :mask-closable="useMaskToClose"
    :destroy-on-close="destroyOnClose"
    :class="modalClass"
    @cancel="onClosedHandler"
    @ok="onSaveHandler"
  >
    <BittsLoading
      :is-loading="loading"
      :loading-container-class="loadingContainerClasses"
      :label="loadingLabel"
    >
      <div>
        <header
          v-if="showHeader"
          class="c-bitts-modal__header"
          :class="{
            'c-bitts-modal__divider': showDivider,
          }"
        >
          <div
            v-if="showCloseButton"
            class="c-bitts-modal__close-button"
            @click="onClosedHandler"
          >
            <FontAwesomeIcon
              :icon="['fas', 'xmark']"
              :style="{ height: '20px', width: '20px' }"
            />
          </div>
          <div class="flex justify-between items-center">
            <slot name="title">
              <h1 v-if="title" class="c-bitts-modal__title">
                {{ title }}
                <slot name="suffix" />
              </h1>
            </slot>
          </div>
          <div v-if="$slots.subtitle" class="text-neutral-600 text-base mt-8">
            <slot name="subtitle" />
          </div>
        </header>
        <section
          class="c-bitts-modal__content"
          :class="{
            'p-0': noPadding,
          }"
        >
          <slot name="content">
            <p class="text-neutral-500">
              {{ contentText }}
            </p>
          </slot>
        </section>
        <slot name="footer">
          <footer v-if="showButtons && !loading" class="c-bitts-modal__buttons">
            <div class="c-bitts-modal__buttons--wrapper">
              <BittsButton
                v-if="deleteButton?.text"
                type="danger"
                variant="ghost"
                size="large"
                class="mr-auto"
                data-testid="c-bitts-modal-delete-button"
                :text="deleteButton.text"
                @click="onDeleteButtonEmit"
              />
              <component :is="cancelToolTip ? 'BittsTooltip' : 'div'">
                <div>
                  <BittsButton
                    v-if="showCancelButton"
                    :disabled="cancelDisabled"
                    :text="cancelText"
                    :type="cancelType"
                    :variant="cancelButtonType"
                    :loading="saving"
                    size="large"
                    class="justify-center hidden md:flex"
                    data-testid="c-bitts-modal-cancel-button"
                    @click="onCancelButtonEmit"
                  />
                </div>
                <template #title>
                  <p>{{ cancelToolTip }}</p>
                </template>
              </component>

              <component :is="saveToolTip ? 'BittsTooltip' : 'div'">
                <div>
                  <BittsButton
                    :disabled="disabled"
                    :text="saveText"
                    :type="confirmType"
                    :loading="saving"
                    size="large"
                    data-testid="c-bitts-modal-save-button"
                    class="c-bitts-modal__save"
                    @click="onSaveHandler"
                  />
                </div>
                <template #title>
                  <p>{{ saveToolTip }}</p>
                </template>
              </component>
            </div>
          </footer>
        </slot>
      </div>
    </BittsLoading>
  </Modal>
</template>

<script>
import { useScreenSize } from '@crossbeam/pointbreak';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { Modal } from 'ant-design-vue';

import BittsButton from '../BittsButton/BittsButton.vue';
import BittsLoading from '../BittsLoading/BittsLoading.vue';
import BittsTooltip from '../BittsTooltip/BittsTooltip.vue';

export default {
  name: 'BittsModal',
  components: {
    BittsButton,
    BittsLoading,
    BittsTooltip,
    Modal,
    FontAwesomeIcon,
  },
  inject: ['pointbreak'],
  props: {
    title: {
      type: String,
      default: '',
    },
    contentText: {
      type: String,
      default: '',
    },
    saveText: {
      type: String,
      default: 'Save',
    },
    cancelText: {
      type: String,
      default: 'Cancel',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    cancelDisabled: {
      type: Boolean,
      default: false,
    },
    showCloseButton: {
      type: Boolean,
      default: true,
    },
    // loading is used for loading spinner in modal container
    // i.e. the loading prop for our loading component
    loading: {
      type: Boolean,
      default: false,
    },
    // saving triggers async spinner in the Cancel and Save buttons
    saving: {
      type: Boolean,
      default: false,
    },
    showButtons: {
      type: Boolean,
      default: true,
    },
    // Overrides cancel visiblity
    showCancelButton: {
      type: Boolean,
      default: true,
    },
    showHeader: {
      type: Boolean,
      default: true,
    },
    noPadding: {
      type: Boolean,
      default: false,
    },
    visible: {
      type: Boolean,
      default: false,
    },
    showDivider: {
      type: Boolean,
      default: false,
    },
    // Be default, x'ing, pressing escape or hitting the cancel button will all emit the same value
    // Setting this to true will trigger the cancel button to emit a more explicte 'cancelled' value
    useCancelButtonEmitter: {
      type: Boolean,
      default: false,
    },
    // controls if ESC key can close modal
    useKeyboardToClose: {
      type: Boolean,
      default: true,
    },
    // controls if clicking outside of modal can close modal
    useMaskToClose: {
      type: Boolean,
      default: true,
    },
    cancelToolTip: {
      type: String,
      default: '',
      required: false,
    },
    saveToolTip: {
      type: String,
      default: '',
      required: false,
    },
    cancelType: {
      type: String,
      default: 'neutral',
      validator(value) {
        return ['primary', 'danger', 'neutral', 'white'].includes(value);
      },
    },
    cancelButtonType: {
      type: String,
      default: 'fill',
      validator(value) {
        return ['fill', 'outline', 'ghost'].includes(value);
      },
    },
    confirmType: {
      type: String,
      default: 'primary',
      validator(value) {
        return ['primary', 'danger', 'neutral'].includes(value);
      },
    },
    width: {
      type: [Number, String],
      default: 500,
    },
    destroyOnClose: {
      type: Boolean,
      default: false,
    },
    loadingLabel: {
      type: String,
      default: '',
    },
    deleteButton: {
      type: Object,
      default: () => {
        // do nothing
      },
    },
  },
  emits: ['closed', 'saved', 'cancelled', 'deleted'],
  setup() {
    const { isMobile } = useScreenSize();
    return { isMobile };
  },
  computed: {
    modalClass() {
      return this.isMobile ? 'c-bitts-modal__mobile' : null;
    },
    loadingContainerClasses() {
      return 'h-screen md:h-auto md:min-h-[240px] overflow-y-auto md:overflow-visible min-w-[400px]';
    },
  },
  methods: {
    onClosedHandler() {
      this.$emit('closed');
    },
    onSaveHandler(e) {
      this.$emit('saved', e);
    },
    onDeleteButtonEmit() {
      this.$emit('deleted');
    },
    onCancelButtonEmit() {
      this.useCancelButtonEmitter
        ? this.$emit('cancelled')
        : this.$emit('closed');
    },
  },
};
</script>

<style lang="pcss">
[class^='ant-modal'] {
  font-family: inherit;
}
.c-bitts-modal__mobile {
  width: 100% !important;
  @apply left-0 top-0 m-0 max-w-full;
}
.c-bitts-modal {
  .ant-modal {
    @apply pb-0;
    .ant-modal-content {
      @apply p-0;
    }
  }

  .c-bitts-modal__divider {
    @apply border-b border-neutral-border pb-24;
  }

  .ant-modal-close {
    @apply hidden;
  }

  .c-bitts-modal__close-button {
    @apply cursor-pointer text-neutral-text-button flex mr-16 md:mr-0 ml-auto justify-end;
    &:hover {
      @apply opacity-80;
    }
  }

  .c-bitts-modal__save {
    @apply justify-center w-full md:w-auto ml-0 md:ml-8;
  }

  .ant-modal-content {
    @apply h-full min-h-screen md:min-h-[auto] md:h-auto overflow-y-auto md:overflow-visible
    rounded-none md:rounded-3xl border-none md:border-solid md:border md:border-neutral-border
    shadow-overlay;
  }

  .ant-modal-body {
    @apply p-0;
  }

  .c-bitts-modal__content {
    @apply p-16 md:p-24;
  }

  .c-bitts-modal__buttons {
    @apply px-16 py-16 md:px-24 border-t border-neutral-border;
  }

  .c-bitts-modal__buttons--wrapper {
    @apply md:flex md:justify-end;
  }

  .c-bitts-modal__header {
    @apply px-16 pt-16 md:px-24 md:pt-24;
  }

  .c-bitts-modal__title {
    @apply text-xl md:text-lg text-neutral-900 font-bold leading-8;
  }

  /* completely overriding ant d footer, it's too opinionated for our needs */
  .ant-modal-footer {
    @apply hidden;
  }
}

.ant-modal-mask {
  @apply bg-neutral-900 bg-opacity-40;
}
</style>
